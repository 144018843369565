<template>
  <div class="main">
    <div class="main_box" v-loading="loading1">
      <div class="main_form">
        <div class="main_form_list">
          <div class="flex_center main_form_list_item">
            <div class="main_form_list_item_title">选择类型：</div>
            <div class="flex main_form_list_item_center">
              <div class="flex main_form_list_item_center_margin">
                <div :class="item.name === noticeNature ? 'main_form_sort_item main_form_sort_item_on' : 'main_form_sort_item'" v-for="(item, index) in ownedAllNotices" :key="item.value" @click="onTime(item, 'noticeNature')">
                  <span>{{item.name}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex_center main_form_list_item">
            <div class="main_form_list_item_title">省份地区：</div>
            <div class="flex main_form_list_item_center">
              <el-cascader v-model="addressDataCode" :options="addressDataNew" @change="handleChange"></el-cascader>
            </div>
          </div>
          <div class="flex_center main_form_list_item">
            <div class="main_form_list_item_title">行业分类：</div>
            <div class="flex main_form_list_item_center">
              <div class="flex main_form_list_item_center_margin">
                <div :class="item.name === industryv2 ? 'main_form_sort_item main_form_sort_item_on' : 'main_form_sort_item'" v-for="(item, index) in industryv2List" :key="item.value" @click="onTime(item, 'industryv2')">
                  <span>{{item.name}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex_center main_form_list_item">
            <div class="main_form_list_item_title">选择企业：</div>
            <div class="flex main_form_list_item_center">
              <el-select
                v-model="unicode"
                clearable
                filterable
                remote
                reserve-keyword
                placeholder="请输入"
                :remote-method="remoteMethod"
                :loading="loading"
                @visible-change="blurQualification"
              >
                <el-option
                  v-for="item in unicodeList"
                  :key="item.code"
                  :label="item.fullName"
                  :value="item.unicode">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="flex_center main_form_list_item">
            <div class="main_form_list_item_title">时间筛选：</div>
            <div class="flex main_form_list_item_center">
              <div class="flex main_form_list_item_center_margin">
                <div :class="item.value === timeDateType ? 'main_form_sort_item main_form_sort_item_on' : 'main_form_sort_item'" v-for="(item, index) in timeDate" :key="item.value" @click="onTime(item, 'timeDate')">
                  <span>{{item.name}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex_center main_form_list_item">
            <div class="main_form_list_item_title">预算金额：</div>
            <div class="flex main_form_list_item_center">
              <div class="flex main_form_list_item_center_margin">
                <div :class="item.value === priceItem ? 'main_form_sort_item main_form_sort_item_on' : 'main_form_sort_item'" v-for="(item, index) in priceList" :key="item.value" @click="onTime(item, 'priceItem')">
                  <span>{{item.name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RecruitTable :dataObj="dataObj" @toDetail="toDetail" @handleCurrentChange="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
  import { throttle, TimeRange, geTime } from '@/utils/public'
  import { urlErpZbProjectNoticeListPageWithPrj, urlDcEnterpriseHighPage, urlErpZbProjectListPage } from '@/api/recruit';
  import { mapState } from 'vuex'
  export default {
    name: "subcontractAcquisition",
    components: { RecruitTable: () => import('../components/recruit-table.vue') },
    data() {
      return {
        dataObj: {},
        addressDataCode: [],
        addressDataName: [],
        ownedAllNotices: [
          {name: "全部", value: 'all'},
          {name: "招标公告", value: 'publish'},
          {name: "变更公告", value: 'change'},
          {name: "流标公告", value: 'failure'},
          {name: "澄清答疑", value: 'answerQuestions'},
          {name: "中标公示", value: 'successPublish'},
          {name: "开标结果", value: 'openResult'},
          {name: "履约信息", value: 'promiseInfo'}
        ],
        noticeNature: '全部',
        industryv2List: [
          {name: '全部', value: 0},
          {name: '建筑工程', value: 1},
          {name: '监理检测', value: 2},
          {name: '造价咨询', value: 3},
          {name: '政府采购', value: 4},
          {name: '勘察设计', value: 5}
        ],
        industryv2: '全部',
        timeDate: [
          {name: "全部", value: "all"},
          {name: "一周内", value: 7},
          {name: "一个月内", value: 30},
          {name: "三个月内", value: 90},
          // {name: "自定义", value: 'custom'}
        ],
        timeDateType: 'all',
        priceList: [
          {name: "不限", startBudgetValue: '', endBudgetValue: '', value: 0},
          {name: "无预算金额", startBudgetValue: 0, endBudgetValue: 0, value: 1},
          {name: "500万以内", startBudgetValue: 0, endBudgetValue: 5000000, value: 2},
          {name: "500万-3000万", startBudgetValue: 5000000, endBudgetValue: 30000000, value: 3},
          {name: "3000万-5000万", startBudgetValue: 30000000, endBudgetValue: 50000000, value: 4},
          {name: "一亿以上", startBudgetValue: 100000000, endBudgetValue: 999999999999, value: 5},
          // {name: "自定义"}
        ],
        priceItem: 0,
        unicodeList: [],
        unicode: '',
        loading1: false,
        loading: false
      }
    },
    mounted() {
      this.getDataList();
      this.getDcEnterpriseHighPage();
    },
    computed: {
      ...mapState({
        addressDataNew: state => state.address.addressDataNew
      })
    },
    watch: {
      addressDataNew: {
        handler(val) {
          return val;
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      blurQualification(type) {
        if (!type) {
          this.getDcEnterpriseHighPage();
          this.getDataList();
        }
      },
      remoteMethod: throttle(function(e) {
        this.getDcEnterpriseHighPage(e)
      }, 500),
      async getDcEnterpriseHighPage(name) {
        this.loading = true;
        const param = {
          current: 1,
          name: name || '',
          size: 100
        };
        const res = await urlDcEnterpriseHighPage(param);
        this.unicodeList = res.data.records;
        this.loading = false;
      },
      toDetail(e) {
        this.$router.push({path: "/subcontract-acquisition/details", query: { tenderingCode: e, tenderingName: this.noticeNature === '全部' ? '招标公告' : this.noticeNature }});
      },
      handleChange(e) {
        let fn = (item, index, goItem, goIndex) => {
          if (item[index].value === goItem[goIndex]) {
            this.addressDataName.push(item[index].label);
            goIndex++;
            if (goIndex === goItem.length) return;
            fn(item[index].children, 0, goItem, goIndex)
          }
          index++;
          if (index === item.length) return;
          fn(item, index, goItem, goIndex)
        };
        fn(this.addressDataNew, 0, e, 0);
        this.getDataList();
      },
      // 选择发布时间
      onTime(item, type) {
        switch (type) {
          case 'noticeNature':
            if (this.noticeNature === item.name) return;
            this.noticeNature = item.name;
            break;
          case 'industryv2':
            if (this.industryv2 === item.name) return;
            this.industryv2 = item.name;
            break;
          case 'timeDate':
            if (this.timeDateType === item.value) return;
            this.timeDateType = item.value;
            break;
          case 'priceItem':
            if (this.priceItem === item.value) return;
            this.priceItem = item.value;
            break;
          default:
            break;
        }
        this.getDataList();
      },
      // 获取首页数据信息
      async getDataList(current) {
        this.loading1 = true;
        const { endBudgetValue, startBudgetValue } = this.priceList[this.priceItem]
        let param;
        let res;

        if (this.$route.query?.search) {
          param = {
            current: current || 1,
            projectName: this.$route.query.search,
            size: 20
          };
          res = await urlErpZbProjectListPage(param);
        } else {
          param = {
            current: current || 1,
            size: 20,
            city: this.addressDataName[1] || '', // 城市
            channelName: this.noticeNature === '全部' ? '' : this.noticeNature, // 公告类型2
            province: this.addressDataName[0] || '', // 省份
            publishDateEnd: this.timeDateType === 'all' ? null : this.timeDateType === 'custom' ? null : geTime(TimeRange(this.timeDateType)[1], 'yyyy.MM.dd HH:mm:ss'), // 查询公开日期范围f：结果日期
            publishDateStart: this.timeDateType === 'all' ? null : this.timeDateType === 'custom' ? null : geTime(TimeRange(this.timeDateType)[0], 'yyyy.MM.dd HH:mm:ss'), // 查询公开日期范围：开始日期
            budgetMoneyMin: startBudgetValue,
            budgetMoneyMax: endBudgetValue,
            enUnicode: this.unicode,
            projectType: this.industryv2 === '全部' ? '' : this.industryv2
          };
          res = await urlErpZbProjectNoticeListPageWithPrj(param);
        }
        this.dataObj = res.data;
        this.dataObj['webType'] = 'listPageWithPrj';
        this.loading1 = false;
      },
      // 分页
      handleCurrentChange(val) {
        this.getDataList(val);
      }
    }
  }
</script>

<style scoped lang="less">
  .main_form {
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #E4EAF2;
    .main_form_list {
      .main_form_list_item {
        padding-bottom: 20px;
        .main_form_list_item_title {
          padding-right: 10px;
          font-size: 16px;
          color: #4E4E4E;
        }
        .main_form_list_item_center {
          flex: 1;
          /deep/ .el-cascader, /deep/.el-select {
            width: 100%;
          }
          .main_form_list_item_center_margin {
            margin:  0 -11px;
            .main_form_sort_item {
              height: 32px;
              line-height: 32px;
              padding: 0 8px;
              margin: 0 11px;
              cursor: pointer;
            }
            .main_form_sort_item_on {
              background-color: #EDF4FF;
              border-radius: 4px;
              color: #1890FF;
            }
            .main_form_list_item_center_margin_item {
              height: 32px;
              line-height: 32px;
              padding: 0 8px;
              margin: 5px 13px;
              cursor: pointer;
              background-color: #F6F7FB;
              color: #4E4E4E;
            }
            .main_form_list_item_center_margin_item:hover {
              background-color: #EDF4FF;
            }
          }
        }
      }
    }
  }
</style>